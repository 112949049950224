<template>
  <div id="app">
    <div id="nav"></div>
    <router-view v-if="router" />
  </div>
</template>

<script>
export default {
  porvide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      router: true,
    };
  },
  methods: {
    goback() {
      console.log("页面进行了路由操作");
    },
    reload() {
      this.router = false;
      this.$nextTick(() => {
        this.router = true;
      });
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 0.7267vw;
}

@media (min-width: 1376px) {
  html {
    font-size: 10px;
  }
}

.add-bold {
  font-weight: 900;
}

.auto-img {
  width: 100%;
  height: 100%;
  display: block;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flex {
  display: flex;
}
</style>
